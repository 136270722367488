/*global $*/
'use strict';

var getCDSContent = (function () {
  var req = {
    method: 'GET',
    url: '/assets/cds-cache/events.json',
    dataType: 'json',
    crossDomain: false
  };
  jQuery.ajax(req).success(function (res) {
    //write to document
    if (res.probezeiten.childs !== '') {
      var probezeitenTemplate = '<span data-open="probezeitenModal" class="probezeiten probezeitenClick animated infinite pulse"></span>';
      jQuery('.introbg').append(probezeitenTemplate);

      var probezeitenItems = res.probezeiten.childs;
      var probezeitenItem = probezeitenItems[0].probezeiten.value.properties;

      var $modal = jQuery('<div>', {
        'class': 'large reveal',
        'id': 'probezeitenModal',
        'aria-hidde': 'false',
        'style': 'top: 45px',
        'tabindex': '-1'
      });

      jQuery('.introbg').append($modal);

      var $row = jQuery('<div>', {'class': 'row align-middle eventmodal'});

      $row.append('<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>');

      $modal.append($row);

      var $column = jQuery('<div>', {'class': 'column small-centered small-12 medium-10 large-10 text-center'});

      $row.append($column);

      $column.append('<h2>' + probezeitenItem.title.value + '</h2>' + probezeitenItem.maintext.value);

      var $column2 = jQuery('<div>', {'class': 'small-12 column'});

      $row.append($column2);

      var $slider = jQuery('<div>', {'class': 'event-slider'});


      $column2.append($slider);

      jQuery('.event-slider').show();

      var preloadImageArray = [];

      if (probezeitenItem.images.value.length > 0) {
        for (var i = 0; probezeitenItem.images.value.length > i; i++) {

          if (i === 0) {
            preloadImageArray.push(probezeitenItem.images.value[i].properties.publicUrl);
            $slider.append('<img data-lazy="' + probezeitenItem.images.value[i].properties.publicUrl + '"/>');
          } else {
            $slider.append('<img data-lazy="' + probezeitenItem.images.value[i].properties.publicUrl + '"/>');
          }
        }
      }

      if (preloadImageArray.length > 0) {
        $slider.slick({
          lazyLoad: 'ondemand',
          infinite: true,
          speed: 300,
          fade: false,
          cssEase: 'linear',
          adaptiveHeight: true,
          autoplay: false,
          autoplaySpeed: 3000,
          pauseOnHover: false,
          prevArrow: '<div class="slick-arrow slick-prev"></div>',
          nextArrow: '<div class="slick-arrow slick-next"></div>',
        });

        $slider.slick('slickGoTo', 1);
      }

      //Eventlistener Modal öffnen
      jQuery(document).on('click', '.probezeitenClick', function () {
        window.location.href = 'https://www.tischmacher-weinpost.de/gutscheine.html#openfirstevent'
        // var modal = new Foundation.Reveal(jQuery('#probezeitenModal'));
        // modal.open();
        // $slider.slick('slickGoTo', 0);
      });

      jQuery(document).on('click', '#closeProbezeiten', function () {
        var modal = new Foundation.Reveal(jQuery('#probezeitenModal'));
        modal.destroy();
      });

    }
  });
})();
